// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

// import { PDFViewer } from "@react-pdf/renderer";
//     import Invoice from "./invoice";

//     function App() {
//       return (
//         <div>
//           <PDFViewer width="1000" height="650" className="app" >
//             <Invoice />
//           </PDFViewer>
//         </div>
//       );
//     }

//     export default App;




    import PdfCard from "./pdfCard";

    function App() {
      const cards = {  maxWidth: "1200px", margin: "0 auto", display: "grid", gap: "1rem", padding : '20px', gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"}
      return (
        <div>
          <h2 style={{textAlign:'center'}}>List of invoices</h2>
          <div style={cards}>
            <PdfCard title="Invoice"/>
            
          </div>
        </div>
      );
    }

    export default App;